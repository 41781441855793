import axios from 'axios';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// material
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/FaqFilter';

// components
import FaqEdit from '../components/FaqEdit';
import Popup from '../components/FaqStatusChange';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// import Popup1 from '../components/popups/FaqPopular';
import PriorityUpdatePopup from '../components/PriorityUpdatePopup';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Faqs() {
  const [faqData, setFaqData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [faqCount, setFaqCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [priorityUpdateIndex, setPriorityUpdateIndex] = useState();
  const [faq, setFaq] = useState({});
  const [editOpen, setEditOpen] = useState(false);

  const faqSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
    faqType: Yup.string().required('FAQ type is required').oneOf(['GENERAL', 'COURSE'], 'Invalid FAQ type'),
    isBlocked: Yup.string().required('Status is required'),
    courseIds: Yup.array().when('faqType', {
      is: 'COURSE',
      then: Yup.array().required('Course is required'),
    }),
  });

  const handlePriorityChange = (index) => {
    setPriorityUpdateIndex(index);
  };

  const formik = useFormik({
    initialValues: {
      isBlocked: 'false',
      question: '',
      answer: '',
      faqType: 'GENERAL',
      courseIds: [],
    },
    validationSchema: faqSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/faq`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getFaqs(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleEditFaq = (item) => {
    console.log('item', item);
    setFaq(item);
    setEditOpen(true);
  };

  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const setCourses = (value) => {
    // Split the value by commas and trim each item, then filter out empty values
    const courses = [
      ...new Set(
        value
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item !== '')
      ),
    ];
    console.log(courses);
    formik.setFieldValue('courseIds', courses);
  };

  const handleClose = (refresh = false, message = '') => {
    console.log(priorityUpdateIndex);
    setSelectedIndex();
    setPriorityUpdateIndex();
    if (refresh) {
      getFaqs(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getFaqs(temp);
  };

  const getFaqs = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/faq/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setFaqCount(res.data.data.maxRecords);
          setFaqData(res.data.data.data);
          setIsLoading(false);
        }
        console.log(priorityUpdateIndex);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getFaqsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/faq/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setFaqCount(res.data.data.maxRecords);
          setFaqData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, search: e.target.value };
    setOptions(temp);
    setPages(0);
    getFaqsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    // if (filter.isPopular.length) {
    //   temp.isPopular = filter.isPopular;
    // }

    setOptions(temp);
    setPages(0);
    getFaqs(temp);
  };
  useEffect(() => {
    getFaqs(options);
  }, []);

  const callAPI = () => {
    getFaqs(options);
  };

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getFaqs(temp);
  }, [size]);

  return (
    <>
      <Page title="FAQ">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add faq Dialog */}
            <Dialog open={open} onClose={closeAddUserModal}>
              <DialogTitle>Add Faq</DialogTitle>
              <Grid container paddingBottom={'10px'}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width="500px">
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              multiline
                              label="Question"
                              fullWidth
                              value={values.question}
                              {...getFieldProps('question')}
                              error={Boolean(touched.question && errors.question)}
                              helperText={touched.question && errors.question}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              multiline
                              label="Answer"
                              fullWidth
                              value={values.answer}
                              {...getFieldProps('answer')}
                              error={Boolean(touched.answer && errors.answer)}
                              helperText={touched.answer && errors.answer}
                            />
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              select
                              label="FAQ Type"
                              value={values.faqType}
                              fullWidth
                              {...getFieldProps('faqType')}
                              error={Boolean(touched.faqType && errors.faqType)}
                              helperText={touched.faqType && errors.faqType}
                            >
                              <MenuItem value={'GENERAL'}>General</MenuItem>
                              <MenuItem value={'COURSE'}>Course</MenuItem>
                            </TextField>

                            <TextField
                              select
                              label="Status"
                              value={values.isBlocked}
                              fullWidth
                              {...getFieldProps('isBlocked')}
                              error={Boolean(touched.isBlocked && errors.isBlocked)}
                              helperText={touched.isBlocked && errors.isBlocked}
                            >
                              <MenuItem value={'false'}>Active</MenuItem>
                              <MenuItem value={'true'}>Blocked</MenuItem>
                            </TextField>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              label="Add Course IDs (comma separated)"
                              fullWidth
                              disabled={values.faqType === 'GENERAL'}
                              onChange={(e) => {
                                setCourses(e.target.value);
                              }}
                              error={Boolean(touched.courseIds && errors.courseIds)}
                              helperText={touched.courseIds && errors.courseIds}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddUserModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add FAQ
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add faq dialog end */}
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Faqs
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                      Add FAQ
                    </Button>

                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Question</TableCell>
                          <TableCell>Answer</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {faqData?.map((item, id) => (
                          <TableRow key={id} >
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            {/* <TableCell>{item.name}</TableCell> */}
                            <TableCell>{item.question}</TableCell>
                            <TableCell>
                              {item.answer.length > 150 ? item.answer.substring(0, 150) + '...' : item.answer}
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }}>
                              <Tooltip title=" Click to Change Priority">
                                <Typography onClick={() => handlePriorityChange(id)}>
                                  {item.priority ? item.priority : '-'}
                                </Typography>
                              </Tooltip>
                              {priorityUpdateIndex === id && (
                                <PriorityUpdatePopup item={item} handleClose={handleClose} />
                              )}
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="false">Active</MenuItem>
                                  <MenuItem value="true">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleEditFaq(item)}>
                                <Iconify icon="mdi:pencil" />
                              </IconButton>
                              {editOpen && (
                                <FaqEdit
                                  callApi={callAPI}
                                  closeAddUserModal={() => {
                                    setEditOpen(false);
                                    setFaq({});
                                  }}
                                  faq={faq}
                                  open={editOpen}
                                  setOpen={setEditOpen}
                                  key={faq._id}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={faqCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
