import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Students from './pages/Students';
import AdminProfile from './pages/AdminProfile';
import Teachers from './pages/Teachers';
import Admins from './pages/Admins';
import Courses from './pages/Courses';
import CourseDetails from './pages/CourseDetails';
import Payments from './pages/Payments';
import CourseAddPage from './pages/CourseAddPage';
import Categories from './pages/Categories';
import AdminView from './pages/AdminView';
import TeacherView from './pages/TeacherView';
import UnitOverview from './pages/UnitOverview';
import Testimonials from './pages/Testimonials';
import StudentView from './pages/StudentView';
import CourseEditPage from './pages/CourseEditPage';
import ManageOrders from './pages/ManageOrders';
import ViewOrder from './pages/ViewOrder';
import QnA from './pages/QnA';
import QuizPage from './pages/QuizPage';
import QuizView from './pages/QuizView';
import QuizAnswersView from './pages/QuizAnswersView';
import AssignmentPage from './pages/AssignmentPage';
import AssignmentView from './pages/AssignmentView';
import AssignmentAnswerView from './pages/AssignmentAnswerView';
import CourseStudents from './pages/CourseStudents';
import EnrolledUserPage from './pages/EnrolledUserPage';
import CertificatesPage from './pages/CertificatesPage';
import CertificateEditPage from './pages/CertificateEditPage';
import SupportPage from './pages/SupportPage';
import SupportView from './pages/SupportView';
import ContactUs from './pages/ContactUs';
import ContactUsView from './pages/ContactUsView';
import Faqs from './pages/Faqs';
import Webinars from './pages/Webinars';
import AssignmentSubmissions from './pages/AssignmentSubmissions';

// ----------------------------------------------------------------------

export default function Router() {
  const { currentAdmin } = useSelector((state) => state.admin);
  return useRoutes([
    {
      path: '/dashboard',
      element: currentAdmin ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // { path: 'app', element: <Students /> },
        {
          path: 'students',
          children: [
            { index: true, element: <Students /> },
            { path: 'view', element: <StudentView /> },
          ]
        },
        {
          path: 'teachers',
          children: [
            { index: true, element: <Teachers /> },
            { path: "view", element: <TeacherView /> }
          ]
        },
        {
          path: 'certificates',
          children: [
            { index: true, element: <CertificatesPage /> },
            { path: "edit", element: <CertificateEditPage /> }
          ]
        },
        {
          path: 'admin',
          children: [
            { index: true, element: <Admins /> },
            { path: 'profile', element: <AdminProfile /> },
            { path: 'view', element: <AdminView /> }
          ]
        },
        {
          path: 'courses',
          children: [
            { index: true, element: <Courses /> },
            { path: "details", element: <CourseDetails /> },
            { path: "add-course", element: <CourseAddPage /> },
            { path: "unit-overview", element: <UnitOverview /> },
            { path: "edit", element: <CourseEditPage /> },
            {
              path: "quiz",
              children: [
                { index: true, element: <QuizPage /> },
                { path: "view", element: <QuizView /> },
                { path: "answers-view", element: <QuizAnswersView /> }
              ]
            },
            { path: "qna", element: <QnA /> },
            {
              path: "students",
              children: [
                { index: true, element: <CourseStudents /> },
                { path: "view", element: <EnrolledUserPage /> }
              ]
            },
            {
              path: "assignment",
              children: [
                { index: true, element: <AssignmentPage /> },
                { path: "view", element: <AssignmentView /> },
                { path: "answers-view", element: <AssignmentAnswerView /> },

              ]
            }
          ]
        },
        { path: "submissions", element: <AssignmentSubmissions /> },
        { path: "categories", element: <Categories /> },
        { path: "testimonials", element: <Testimonials /> },
        {
          path: "contact-us",
          children: [
            { index: true, element: <ContactUs /> },
            { path: "view", element: <ContactUsView /> }
          ]
        },
        {
          path: "support",
          children: [
            { index: true, element: <SupportPage /> },
            { path: "view", element: <SupportView /> },

          ]
        },
        {
          path: "orders",
          children: [
            { index: true, element: <ManageOrders /> },
            { path: "view", element: <ViewOrder /> }
          ]
          ,
        },
        {
          path: "faq",
          children: [
            { index: true, element: <Faqs /> }
          ]
        },
        {
          path: "webinars",
          children: [
            { index: true, element: <Webinars /> }
          ]
        }
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
