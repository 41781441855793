import { useState } from 'react';
import axios from 'axios';
// material
import { Stack, Dialog, DialogContent, DialogTitle, Button, MenuItem, TextField, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FaqEdit({ faq, closeAddUserModal, open, callApi, setOpen }) {
  const formik = useFormik({
    initialValues: {
      isBlocked: faq.isBlocked,
      question: faq.question,
      answer: faq.answer,
      faqType: faq.faqType,
      courseIds: faq.courseIds,
      id: faq._id,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/faq`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          callApi();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setCourses = (value) => {
    // Split the value by commas and trim each item, then filter out empty values
    const courses = [
      ...new Set(
        value
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item !== '')
      ),
    ];
    console.log(courses);
    formik.setFieldValue('courseIds', courses);
  };

  return (
    <Dialog open={open} onClose={closeAddUserModal}>
      <DialogTitle>Edit Faq</DialogTitle>
      <Grid container paddingBottom={'10px'}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction={'column'} spacing={1} width="500px">
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      type="text"
                      multiline
                      label="Question"
                      fullWidth
                      value={values.question}
                      {...getFieldProps('question')}
                      error={Boolean(touched.question && errors.question)}
                      helperText={touched.question && errors.question}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      type="text"
                      multiline
                      label="Answer"
                      fullWidth
                      value={values.answer}
                      {...getFieldProps('answer')}
                      error={Boolean(touched.answer && errors.answer)}
                      helperText={touched.answer && errors.answer}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      select
                      label="FAQ Type"
                      value={values.faqType}
                      fullWidth
                      {...getFieldProps('faqType')}
                      error={Boolean(touched.faqType && errors.faqType)}
                      helperText={touched.faqType && errors.faqType}
                    >
                      <MenuItem value={'GENERAL'}>General</MenuItem>
                      <MenuItem value={'COURSE'}>Course</MenuItem>
                    </TextField>

                    <TextField
                      select
                      label="Status"
                      value={values.isBlocked}
                      fullWidth
                      {...getFieldProps('isBlocked')}
                      error={Boolean(touched.isBlocked && errors.isBlocked)}
                      helperText={touched.isBlocked && errors.isBlocked}
                    >
                      <MenuItem value={'false'}>Active</MenuItem>
                      <MenuItem value={'true'}>Blocked</MenuItem>
                    </TextField>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      label="Add Course IDs (comma separated)"
                      fullWidth
                      disabled={values.faqType === 'GENERAL'}
                      value={values.courseIds?.length > 0 ? values.courseIds.join(', ') : ''}
                      onChange={(e) => {
                        setCourses(e.target.value);
                      }}
                      error={Boolean(touched.courseIds && errors.courseIds)}
                      helperText={touched.courseIds && errors.courseIds}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={closeAddUserModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Edit FAQ
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}

export default FaqEdit;
