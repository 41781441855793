import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import adminSlice from './adminSlice';
import courseSlice from './courseSlice';
import instructorSlice from './instructorSlice';
import administratorSlice from './administratorSlice';
import studentSlice from './studentSlice';
import drawerSlice from './drawerSlice';

// add the reducers that need to be persisted

const persistConfig = {
    key: 'root',
    storage,
};

const reducers = combineReducers({
    admin: adminSlice,
    course: courseSlice,
    instructor: instructorSlice,
    administrator: administratorSlice,
    student: studentSlice,
    drawer: drawerSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    whitelist: ["drawer"]
});

const persistor = persistStore(store);

export { store, persistor };
